import React from 'react'
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';

import TableContainer from '@mui/material/TableContainer';
import DeleteIcon from '@mui/icons-material/Delete';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import toast from 'react-hot-toast';

import secureApiFetch from '../../services/secureFetchApi';
import DotLoader from '../../styles/DotLoader';
import ColorBadge from '../../ui/ColorBadge'
import DateFormat from '../../ui/DateFormat'
import '../../styles/table.css'

const ScheduleTable = ({ sheduleTable, loading, getSchedule }) => {

  const handleDelete = (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this record?');
    if (confirmDelete) {
      secureApiFetch(`/api/v1/schedule_scan/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
        .then((res) => {
          getSchedule()
          if (res.ok) {
            toast.success('Record deleted successfully!');
          } else {
            toast.error('Failed to delete record. Please try again.');
          }
        })
        .catch((error) => {
          toast.error('An error occurred while deleting the record.');
          console.error('Error deleting record:', error);
        });
    }
  };
  return (
    <div>
      <Paper sx={{ width: '100%' }}>
        <TableContainer component={Paper}>
          <Table className="table-container">
            <TableHead className="table-heading text-left">
              <TableRow>
                <TableCell align="left">Target</TableCell>
                <TableCell align="left">Cron</TableCell>
                <TableCell align="center">Target Type</TableCell>
                <TableCell align="center">Level</TableCell>
                <TableCell align="center">Remaning Scan</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">Last Run</TableCell>
                <TableCell align="left">Created Date</TableCell>
                <TableCell align="left">Last Modified</TableCell>
                <TableCell align="center">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (<tr>
                <TableCell colSpan={11} sx={{ padding: '1.5rem 0rem' }}>
                  <DotLoader />
                </TableCell>
              </tr>)}
              {!loading && sheduleTable.length === 0 && (
                <tr>
                  <td colSpan={10} align="center " className='py-3 font-bold text-xl text-red-600'>
                    Nothing to see here... yet.
                  </td>
                </tr>
              )}
              {!loading && sheduleTable.length > 0 && sheduleTable.length !== 0 && sheduleTable.map((item, index) => (
                <TableRow key={index} className="data-row text-left">
                  <TableCell >
                    <Link to={`/schedulescan/${item.id}`} >
                      {item.target}
                    </Link>
                  </TableCell>
                  <TableCell align="left"> {item.cron} </TableCell>
                  <TableCell align="center"> {item.target_type} </TableCell>
                  <TableCell align="center"> {item.level} </TableCell>
                  <TableCell align="center"> {item.schedule_count} </TableCell>
                  <TableCell align="left">
                    <ColorBadge badge={item.status} />
                  </TableCell>
                  <TableCell align="left">
                    <DateFormat date={item.last_scheduled_for} />
                  </TableCell>
                  <TableCell align="left"> <DateFormat date={item.insert_ts} /> </TableCell>
                  <TableCell align="left"> <DateFormat date={item.update_ts} /> </TableCell>
                  <TableCell align="center">
                    <IconButton onClick={() => handleDelete(item.id)}>
                      <DeleteIcon className='text-red-600' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}

export default ScheduleTable