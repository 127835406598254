import { IconButton, Tooltip } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import secureApiFetch from '../../../../services/secureFetchApi';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import './print.css'
import ReportPage from './ReportPage';
import ReportIntro from './ReportIntro';
import Overview from './Overview';
import Note from './Note';

import RenderSubfinder from '../scanners/RenderSubfinder';
import RenderWhoisData from '../scanners/RenderWhoisData';
import { RenderSslscan } from '../scanners/RenderSslscan';
import RenderOpenai from '../scanners/RenderOpenai';
import RenderShcheck from '../scanners/RenderShcheck';
import RenderWebvulnFinder from '../scanners/RenderWebvulnFinder';
import { formatString, pataString } from './SubName';
import KeyAssesment from './KeyAssesment';
import Renders3scanner from '../scanners/Renders3scanner';
import CompanyInfo from '../form';
import Rendercrlfsuite from '../scanners/RenderRustScan';

const ResultFiltered = () => {
    const [data, setData] = useState([]);
    const [groupedData, setGroupedData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [open, setOpen] = useState(false);
    const printRef = useRef(null);

    const jobIds = localStorage.getItem('jobIds')?.split(',') || [];
    const companyInfo = JSON.parse(localStorage.getItem('companyInfo'));

    const handlePrint = () => {
        if (printRef.current) {
            printRef.current.handlePrint();
        }
    };
    const handleClickOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)


    useEffect(() => {
        if (jobIds.length === 0) {
            setError('No job data was found. Please check your entries and try again.');
            setLoading(false);
        } else {
            fetchJobData(jobIds);
        }
    }, []);

    const fetchJobData = async (jobIds) => {
        try {
            const responses = await Promise.all(
                jobIds.map((jobId) =>
                    secureApiFetch(`/api/v1/result/job_id/${jobId}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }).then((res) => (res.status === 204 ? null : res.json()))
                )
            );

            const validData = responses.filter((item) => item && !item.error);

            if (validData.length === 0) {
                setError("No valid job data found. Please check your entries and try again.");
            } else {
                setData(validData);
            }
        } catch (err) {
            console.error(err);
            setError('An error occurred while fetching the data. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const groupDataByScanType = (data) => {
        return data.reduce((acc, item) => {
            const target = Object.keys(item)[0];
            const scans = item[target];

            Object.entries(scans).forEach(([scanType, scanEntries]) => {
                const validEntries = scanEntries.filter(entry => entry.result && entry.result.length > 0);

                if (validEntries.length > 0) {
                    if (!acc[scanType]) acc[scanType] = [];
                    acc[scanType].push(...validEntries.map((entry) => ({ ...entry, target })));
                }
            });

            return acc;
        }, {});
    };


    useEffect(() => {
        if (data.length > 0) {
            setGroupedData(groupDataByScanType(data));
        }
    }, [data]);
    return (
        <div className="min-h-screen bg-gray-100 p-2">
            <ReactToPrint ref={printRef} content={() => document.querySelector('#table-to-print')} />
            <div className="bg-gray-800 p-4 rounded-md shadow-md">
                <div className='flex items-center justify-between'>
                    <h1 className="text-2xl font-bold text-white mb-4">Result</h1>
                    <IconButton onClick={handleClickOpen} >
                        <Tooltip title='Print this out!'>
                            <PrintIcon className='text-[#ffff]' />
                        </Tooltip>
                    </IconButton>
                </div>
                <div className="bg-gray-800 rounded-md shadow-md">
                    {loading ? (
                        <div className="bg-white p-5 rounded-md shadow-sm h-[80vh] text-lg flex justify-center items-center">
                            Just a moment, we're fetching the details...
                        </div>
                    ) : error ? (
                        <div className="bg-white p-5 rounded-md shadow-sm h-[80vh] text-center">
                            <h2 className="text-xl font-bold text-red-500">{error}</h2>
                        </div>
                    ) : (
                        <div id="table-to-print" className="bg-white p-5 rounded-md shadow-sm min-h-[80vh]">
                            <div className="cover-page">
                                <ReportPage nameTarget={companyInfo} />
                                <ReportIntro companyInfo={companyInfo} />
                                <Overview />
                                <KeyAssesment />
                            </div>

                            {Object.entries(groupedData).map(([scanType, entries], jobIndex) => (
                                <div key={scanType} className="mb-6 page-break paddingTop">
                                    <div className="flex flex-col mb-4">
                                        <h3 className="text-xl font-semibold text-white bg-[#3A3D89] py-1 px-1">
                                            {jobIndex + 1}. {formatString(scanType)}
                                        </h3>
                                        <div className="text-lg text-gray-600">
                                            {pataString(scanType)}
                                        </div>
                                    </div>
                                    {entries.length === 0 ? <div className="text-gray-600 italic mt-4 pl-4 bg-[#F9F9F9] p-4 rounded-md shadow-inner"> No results available. </div> : entries.map((entry, index) => (
                                        <div key={index} className="mt-4 pl-4 bg-[#F9F9F9] p-4 rounded-md shadow-inner">
                                            <h4 className="text-lg font-semibold text-[#3A3D89] mb-2">Target: {entry.target}</h4>
                                            <h4 className="text-lg font-semibold text-[#3A3D89] mb-2">Results:</h4>
                                            <ul className="ml-4">
                                                {Array.isArray(entry.result) && entry.result.map((result, resultIndex) => (
                                                    <div key={resultIndex} className="mt-2">
                                                        {scanType === 'subfinder' && <RenderSubfinder result={result} />}
                                                        {scanType === 'whois' && <RenderWhoisData result={result} />}
                                                        {scanType === 'sslscan' && <RenderSslscan data={result} />}
                                                        {scanType === 'openai' && <RenderOpenai result={result} />}
                                                        {scanType === 'shcheck' && <RenderShcheck result={result} />}
                                                        {scanType === 'webvuln_finder' && <RenderWebvulnFinder result={result} />}
                                                        {scanType === 'crlfsuite' && <Rendercrlfsuite result={result} />}
                                                        {scanType !== 'crlfsuite' && scanType !== 's3scanner' && scanType !== 'webvuln_finder' && scanType !== 'shcheck' && scanType !== 'subfinder' && scanType !== 'openai' && scanType !== 'sslscan' && scanType !== 'whois' && (
                                                            <ul className="text-md list-inside list-disc text-gray-800">
                                                                {Object.entries(result).map(([key, value]) => (
                                                                    <li key={key}>
                                                                        <span className="font-semibold text-[#3A3D89]">{key}: </span>
                                                                        {value}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </div>
                                                ))}
                                                {(!entry.result || !Array.isArray(entry.result) || entry.result.length === 0 || entry.result.every(result => Object.keys(result).length === 0)) && (
                                                    <div className="text-gray-600 italic">
                                                        No results available.
                                                    </div>
                                                )}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            ))}
                            <div className='note-page'>
                                <Note />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    Report Details
                </DialogTitle>
                <DialogContent>

                    <CompanyInfo handleClose={handleClose} onPrint={handlePrint} />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ResultFiltered;