import React from 'react';

const Rendercrlfsuite = ({ result }) => {
    return (
        <div className="p-4 bg-gray-50">
            <ul className="list-disc list-inside space-y-3 text-gray-800">
                {Object.entries(result).map(([url, data], index) => (
                    <li key={index}>
                        <strong className="text-[#3A3D89]">URL:</strong>
                        <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-600 hover:underline"
                        >
                            {url}
                        </a>
                        <div>
                            <strong className="text-[#3A3D89]">Vulnerable:</strong>
                            {data.Vulnerable}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Rendercrlfsuite;
